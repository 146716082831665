<template>
  <div class="dd-sort">
    <div
      class="dd-sort_icon"
      @click="sortOpenClose"
    >
      <DropDownIcon />
    </div>
    <transition name="fade">
      <div
        v-if="isOpen"
        v-click-outside="outsideClick"
        class="dd-sort__dropdown"
      >
        <div
          v-for="item in optionsForSort"
          :key="item.value"
          class="dd-sort__dropdown-item"
          :class="{ 'dd-sort__dropdown-item--active' : item.isActive }"
          @click="sortChange(item)"
        >
          <div class="dd-sort__dropdown-item-icon">
            <DropDownCheckIcon />
          </div>
          <div class="dd-sort__dropdown-item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DropDownIcon from './svg/DropDownIcon'
import DropDownCheckIcon from './svg/DropDownCheckIcon'

export default {
  components: {
    DropDownIcon,
    DropDownCheckIcon,
  },
  data() {
    return {
      optionsForSort: [
        {
          text: 'Highest amount',
          value: 'hamount',
          isActive: true,
        },
        {
          text: 'Lowest amount',
          value: 'lamount',
          isActive: false,
        },
        {
          text: 'Highest risk',
          value: 'hrisk',
          isActive: false,
        },
        {
          text: 'Lowest risk',
          value: 'lrisk',
          isActive: false,
        },
      ],
      isOpen: false,
    }
  },
  computed: {
    activeSortValue() {
      const activeOption = this.optionsForSort.find(option => option.isActive)
      return activeOption ? activeOption.value : null
    },
  },
  methods: {
    sortOpenClose() {
      this.isOpen = !this.isOpen
    },
    outsideClick() {
      this.isOpen = false
    },
    sortChange(item) {
      if(!item.isActive) {
        this.optionsForSort.find(item => item.isActive).isActive = false
        item.isActive = true
        this.sortOpenClose()
        this.$emit('sortChange', this.activeSortValue)
      }
    }
  }
}
</script>

<style scoped>
.dd-sort {
  width: 29px;
  height: 29px;
  background-color: var(--pale-blue);
  border-radius: 8px;
  position: relative;
}
.dd-sort_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dd-sort__dropdown {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 16px 16px 12px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(9, 21, 64, 0.1);
  position: absolute;
  left: 50%;
  top: calc(100% + 8px);
  transform: translate(-50%, 0);
}
.dd-sort__dropdown-item {
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  cursor: pointer;
}
.dd-sort__dropdown-item + .dd-sort__dropdown-item {
  margin-top: 12px;
}
.dd-sort__dropdown-item-text {
  font-size: 12px;
  line-height: 1;
  white-space: nowrap;
}
.dd-sort__dropdown-item-icon {
  overflow: hidden;
}
.dd-sort__dropdown-item-icon svg {
  opacity: 0;
}
.dd-sort__dropdown-item--active {
  cursor: default;
}
.dd-sort__dropdown-item--active .dd-sort__dropdown-item-icon svg {
  opacity: 1;
}
</style>