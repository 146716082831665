<template>
  <div class="swap__data">
    <div class="swap__data-deposit">
      <div class="swap__data-title">Deposit</div>
      <div class="swap__data-amount">
        {{ depositData(input) }}
      </div>
      <div class="fs-10 price">
        {{ formatByPrice(inputAmountCur) }}
      </div>
    </div>
    <div class="swap__data-withdraw">
      <div class="swap__data-title">Withdraw</div>
      <div class="swap__data-amount">
        {{ withdrawData(output) }}
      </div>
      <div class="fs-10 price">
        {{ formatByPrice(outputAmountCur) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatAmount } from '@/utils/format-amount'
import { formatByPrice } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'

export default {
  props: {
    currencies: {
      type: Object,
      default: () => {},
    },
    input: {
      type: Array,
      default: () => [],
    },
    output: {
      type: Array,
      default: () => [],
    },
    coin: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      inputAmountCur: null,
      outputAmountCur: null,
    }
  },
  methods: {
    formatAmount,
    formatByPrice,
    toComaSeparate,
    depositData(data) {
      const {choosenObj, value} = this.dataLogic(data)
      this.inputAmountCur = choosenObj.amountCur
      return value
    },
    withdrawData(data) {
      const {choosenObj, value} = this.dataLogic(data)
      this.outputAmountCur = choosenObj.amountCur
      return value
    },
    dataLogic(data) {
      let objWithCurrency = data.find(item => 'currency' in item)

      if (objWithCurrency) {
        return {
          choosenObj: objWithCurrency,
          value: this.toComaSeparate(
              formatAmount(objWithCurrency.amount, true, true, this.currencies[objWithCurrency.currency].currency, true, this.currencies[objWithCurrency.currency].decimals)
            ) || '0',
        }
      } else {
        return {
          choosenObj: data[0],
          value: this.toComaSeparate(
              formatAmount(data[0].amount, true, true, this.coin.family.toUpperCase(), true, this.coin.decimals)
            ) || '0',
        }
      }
    },
  }
}
</script>

<style>
.swap__data {
  display: flex;
  column-gap: 80px;
  padding: 8px 0;
}
.swap__data-deposit {
  flex: 0 0 25%;
}
.swap__data-title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
}
.swap__data-amount {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
}
</style>