<template>
  <div class="report-full-tx-section-wrapper">
    <div class="report-full-tx-table-wrapper">
      <SourceTable
        :active-part="activePart"
        :active-source-list="activeSourceList"
        :active-type-entity-item="activeTypeEntityItem"
        :address-part="addressPart"
        :currencies="currencies"
      />
    </div>
  </div>
</template>

<script>
// Components
import SourceTable from '@/pages/report/components/table-components/SourceTable'

export default {
  components: {
    SourceTable,
  },

  props: {
    fullDetailsPart: {
      type: Object,
      default: () => ({}),
    },
    activePart: {
      type: Object,
      required: true,
    },
    activeTypeEntityItem: {
      type: Object,
      default: () => ({}),
    },
    addressPart: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    activeSourceList() {
      if(this.activeTypeEntityItem.name) {
        return (
          this.fullDetailsPart?.data?.sources.filter(row => {
            if (this.activePart.value === 'type') {
              return row.funds.name === this.activeTypeEntityItem.name
            }
            if (this.activePart.value === 'entity') {
              return row.owner === this.activeTypeEntityItem.name
            }
          })
        )
      } else {
        return this.fullDetailsPart?.data?.sources
      }
    },
    currencies() {
      return this.fullDetailsPart?.data?.currencies
    },
  },
  methods: {},
}
</script>

<style>
.report-full-tx-hint-img-wrapper {
  margin-top: 24px;
  margin-bottom: 8px;
}
.report-full-tx-hint-img-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.report-full-tx-hint-text-wrapper {
  color: var(--light-grey-59);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  max-width: 250px;
  margin-bottom: 24px;
}

/* slide-source */
.slide-source-enter-active {
  transition: all 0.4s linear;
}

.slide-source-leave-active {
  transition: all 0.4s linear;
}

.slide-source-enter,
.slide-source-leave-to {
  opacity: 0;
}

.slide-source-table-enter-active {
  transition: all 0.3s linear;
}

.slide-source-table-leave-active {
  transition: all 0s linear;
}

.slide-source-table-enter,
.slide-source-table-leave-to {
  height: 0;
}
</style>
