<template>
  <div class="type-entity-wrapper">
    <div class="type-entity-part-wrapper">
      <div class="type-entity-part-head-wrapper">
        <div class="type-entity-part-head-info-wrapper m-fs-14">
          <div>
            <div 
              v-if="hasTotalReceived"
              class="fs-14"
            >
              <div class="mb-1">
                Source of Funds
              </div>
              <div class="bold-700">
                {{ (hasTotalReceived &&
                  toComaSeparate(
                    formatAmount(addressPart.data.totalReceived, true, 3),
                  )) ||
                  '0' }}
              </div>
              <div 
                v-tooltip="{
                  content: `${priceMessage(
                    'current',
                    addressPart.data.totalReceivedPriceTimestamp,
                  )}`,
                  show: showTooltipPriceMessage,
                  trigger: 'manual',
                }"
                class="fs-10 price"
                @mouseleave="showTooltipPriceMessage = false"
                @mouseover="showTooltipPriceMessage = true"
              >
                {{ hasTotalReceived &&
                  formatByPrice(addressPart.data.totalReceivedPrice) }}
              </div>
            </div>
          </div>
          <div class="flex align-center gap-3">
            <GlSelectButton
              v-if="entityPart.purchased"
              v-model="innerActivePart"
              :options="activePartOptions"
            />
            <DropDownSort @sortChange="sortChange" />
          </div>
          <div 
            v-if="hasTxsBySources"
            class="fs-14"
          >
            <div class="mb-1">
              Evaluated Transactions
            </div>
            <div class="bold-700 text-end">
              {{ toComaSeparate(addressPart.data.numberOfTxsBySources) || '0' }}
            </div> 
          </div>
        </div>
      </div>
      <div
        ref="typeEntityTableWrapperRef"
        class="type-entity-part-table-wrapper"
        :class="{ 'type-entity-part-table-wrapper--close' : tableClose }"
        :style="dynamicStyleForTable"
      >
        <div v-if="!resultDataSortedShare.length">
          Not found
        </div>
        <div v-else>
          <GlScoringBarChart
            ref="scoringBarChart"
            :active-bar-item="innerActiveTypeEntityItem"
            :basis-percent-graph-width="basisPercentGraphWidth"
            :data-table="resultDataSortedShare"
            :pointer-item="fullDetailsPart.purchased"
            :separation-factor-point="separationFactorPoint"
            @scoring-bar-item-click="scoringBarItemClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlSelectButton from '@/components/gl-select-button'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import DropDownSort from '@/pages/report/components/redesign/risk-exposure-components/DropDownSort'
// Utils
import { formatAmount } from '@/utils/format-amount'
import { capitalizeFirstLetter, trancateString } from '@/utils/text-formatter'
import { findMinMaxFields } from '@/utils/model'
import { toComaSeparate } from '@/utils/formatNumber'
import { scrollToSelector } from '@/utils/scroll-to'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
// Vuex
import { mapState } from 'vuex'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlSelectButton,
    GlScoringBarChart,
    DropDownSort,
  },
  mixins: [deviceWidthMixin],
  props: {
    typesPart: {
      type: Object,
      default: () => ({}),
    },
    entityPart: {
      type: Object,
      default: () => ({}),
    },
    fullDetailsPart: {
      type: Object,
      default: () => ({}),
    },
    addressPart: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activePart: {
      type: Object,
      required: true,
    },
    activePartOptions: {
      type: Array,
      required: true,
    },
    activeTypeEntityItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showTooltipPriceMessage: false,
      tableClose: false,
      sortValue: 'hamount',
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinType']),
    basisPercentGraphWidth() {
      if (this.isMobile) return 100
      if (this.isTablet) return 40
      return 50
    },
    step() {
      if (this.isMobile) return 10
      if (this.isTablet) return 10
      return 5
    },
    separationFactorPoint() {
      if (this.isMobile) return 0.25
      if (this.isTablet) return 0.2
      return 0.1
    },
    resultData() {
      if (this.innerActivePart.value === 'type') {
        return this.typesPart.data || []
      } else if (this.innerActivePart.value === 'entity') {
        return this.entityPart.data || []
      }
      return this.typesPart.data
    },
    resultDataSortedShare() {
      return this.resultData.slice().sort((a, b) => {
        if (this.sortValue === 'hamount') {
          return b.share - a.share
        } else if (this.sortValue === 'lamount') {
          return a.share - b.share
        } else if (this.sortValue === 'hrisk') {
          return b.score - a.score;
        } else if (this.sortValue === 'lrisk') {
          return a.score - b.score;
        } else {
          return this.resultData
        }
      })
    },
    innerActivePart: {
      get: function () {
        return this.activePart
      },
      set: function (e) {
        this.$emit('change-active-part', e)
      },
    },
    innerActiveTypeEntityItem: {
      get: function () {
        return this.activeTypeEntityItem
      },
      set: function (e) {
        this.$emit('change-active-type-entity-item', e)
      },
    },
    dynamicStyleForTable() {
      const itemCount = this.resultData.length
      const isMobile = this.windowWidth <= 767

      const maxItems = isMobile ? 5 : 10
      const itemHeight = isMobile ? 123 : 45
      const maxHeight = isMobile ? '615px' : '450px'

      return {
        height: itemCount <= maxItems ? `${itemCount * itemHeight}px` : maxHeight
      };
    },
    hasTxsBySources() {
      return Boolean(this.addressPart?.data?.numberOfTxsBySources)
    },
    hasTotalReceived() {
      return Boolean(this.addressPart?.data?.totalReceived)
    },
  },
  watch: {
    'innerActivePart.value': {
      handler() {
        this.innerActiveTypeEntityItem = {}
        this.tableClose && this.$refs.scoringBarChart.barClick()
      },
      deep: true,
    },
  },
  methods: {
    formatAmount,
    capitalizeFirstLetter,
    findMinMaxFields,
    trancateString,
    toComaSeparate,
    scrollToSelector,
    formatByPrice,
    priceMessage,
    scoringBarItemClick(item) {
      this.tableClose = !this.tableClose
      this.$refs.typeEntityTableWrapperRef.scrollTop = 0
      if (!this.fullDetailsPart.purchased) return
      this.innerActiveTypeEntityItem = item
    },
    sortChange(value) {
      this.sortValue = value
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
}
</script>

<style>
.type-entity-part-table-wrapper {
  overflow-y: auto;
  transition: height 1s ease;
}
.type-entity-part-table-wrapper--close {
  height: 45px !important;
}

.type-entity-part-wrapper .gl-line-steps-wrapper {
  margin-right: 100px;
}

.type-entity-part-head-info-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 42px;
}
.type-entity-part-head-info-wrapper > div:nth-child(1) {
  align-self: flex-start;
}
.type-entity-part-head-info-wrapper > div:nth-child(2) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.type-entity-part-table-wrapper::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.type-entity-part-wrapper .gl-section-wrapper {
  overflow: hidden;
}

@media (max-width: 767px) {
  .type-entity-part-head-info-wrapper {
    display: block;
    padding-top: 48px;
    gap: 8px;
  }
  .type-entity-part-head-info-wrapper > div:nth-child(1) {
    margin-bottom: 16px;
  }
  .type-entity-part-head-info-wrapper > div:nth-child(2) {
    width: 100%;
    top: 0;
    transform: translate(-50%, 0);
  }
  .type-entity-part-head-info-wrapper .text-end {
    text-align: left;
  }
  .type-entity-part-table-wrapper--close {
    height: 123px !important;
  }
}
</style>
