<template>
  <div>
    <div id="report">
      <transition-group
        appear
        name="fade"
      >
        <RequestTokenReport 
          v-if="coinData.supportContracts && !reportPartsLoading"
          key="Requested Token Report"
          :options="addressPart.data.tokens" 
          @newToken="changeToken" 
        />
        <GlSection
          key="Address information"
          class="mb-4"
          :green="!!Object.keys(currentHoveredPart).length"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            <div> Address information </div>
          </template>

          <template #body>
            <div
              v-if="!hasTxs && !reportPartsLoading"
              class="pa-2"
            >
              There are no transactions for this address
            </div>
            <transition-group
              v-else
              name="fade-leave"
            >
              <div
                v-if="reportPartsLoading"
                key="loader-address-info"
                class="report-lock-section-address-info flex align-center justify-center"
              >
                <gl-loader progress-spinner />
              </div>
              <div
                v-else
                key="address-info"
                class="report-section__content-wrapper"
              >
                <div class="report-block-wrapper">
                  <InfoBlock
                    v-if="coinData.supportContracts"
                    class=""
                    inline-view
                    label="Type of the address"
                    :loading="reportPartsLoading"
                    value="Externally-owned Account (EOA)"
                  />
                  <InfoBlock
                    class=""
                    inline-view
                    label="Balance"
                    :loading="reportPartsLoading"
                    :rate="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.price &&
                        formatByPrice(addressPart.data.price)
                    "
                    rate-time="current"
                    :rate-timestamp="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.priceTimestamp
                    "
                    :value="
                      (addressPart &&
                        addressPart.data &&
                        addressPart.data.balance &&
                        toComaSeparate(
                          formatAmount(addressPart.data.balance, coinData.family == 'eth' ? 'justAddBtc' : true),
                        )) ||
                        '0'
                    "
                  />
                  <InfoBlock
                    class=""
                    inline-view
                    label="Number of txs"
                    :loading="reportPartsLoading"
                    :value="
                      (addressPart &&
                        addressPart.data &&
                        toComaSeparate(txsAddPlus(addressPart.data.numberOfTxs, 2000002))) ||
                        '0'
                    "
                  />
                  <InfoBlock
                    v-if="!coinData.supportContracts"
                    class=""
                    inline-view
                    label="First seen"
                    :loading="reportPartsLoading"
                    :value="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.firstSeen &&
                        formatDate(
                          addressPart.data.firstSeen * 1000,
                          'dd.MM.yyyy HH:mm',
                        )
                    "
                  />
                  <InfoBlock
                    v-if="!coinData.supportContracts"
                    class=""
                    inline-view
                    label="Last seen"
                    :loading="reportPartsLoading"
                    :value="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.lastSeen &&
                        formatDate(
                          addressPart.data.lastSeen * 1000,
                          'dd.MM.yyyy HH:mm',
                        )
                    "
                  />
                </div>
                <div class="report-block-wrapper">
                  <InfoBlock
                    v-if="!coinData.supportContracts"
                    class=""
                    inline-view
                    label="Total received"
                    :loading="reportPartsLoading"
                    :rate="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.totalReceived &&
                        formatByPrice(addressPart.data.totalReceivedPrice)
                    "
                    rate-time="current"
                    :rate-timestamp="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.totalReceivedPriceTimestamp
                    "
                    :value="
                      (addressPart &&
                        addressPart.data &&
                        addressPart.data.totalReceived &&
                        toComaSeparate(
                          formatAmount(addressPart.data.totalReceived, true, 3),
                        )) ||
                        '0'
                    "
                  />
                  <InfoBlock
                    v-if="!coinData.supportContracts"
                    class=""
                    inline-view
                    label="Total sent"
                    :loading="reportPartsLoading"
                    :rate="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.totalSent &&
                        formatByPrice(addressPart.data.totalSentPrice)
                    "
                    rate-time="current"
                    :rate-timestamp="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.totalSentPriceTimestamp
                    "
                    :value="
                      addressPart &&
                        addressPart.data &&
                        addressPart.data.totalSent &&
                        toComaSeparate(
                          formatAmount(addressPart.data.totalSent, true, 3),
                        )
                    "
                  />
                  <InfoBlock
                    class=""
                    inline-view
                    label="Total tx received"
                    :loading="reportPartsLoading"
                    :value="
                      addressPart &&
                        addressPart.data &&
                        toComaSeparate(txsAddPlus(addressPart.data.totalTxReceived, 1000001))
                    "
                  />
                  <InfoBlock
                    class=""
                    inline-view
                    label="Total tx sent"
                    :loading="reportPartsLoading"
                    :value="
                      (addressPart &&
                        addressPart.data &&
                        toComaSeparate(txsAddPlus(addressPart.data.totalTxSent, 1000001))) ||
                        '0'
                    "
                  />
                </div>
              </div>
            </transition-group>
          </template>
        </GlSection>
        <GlSection
          key="Owner Information"
          class="mb-4"
          :green="showHoverOnSectionOwner"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            <div> Owner Information </div>
          </template>
          <template #body>
            <transition-group name="fade-leave">
              <LockReportSection
                v-if="
                  !ownerPart.purchased ||
                    startAddressCheckLoading ||
                    reportPartsLoading
                "
                key="owner-lock"
                :current-hovered-part="currentHoveredPart"
                :loading-section="startAddressCheckLoading"
                owner
                :part-data="ownerPart"
                :show-buy-button="false"
                text="Included with any next report parts"
                @button-click="part => $emit('click-unlock-now', part)"
                @update-hovered-part="
                  part => $emit('update-hovered-part', part)
                "
              />
              <ReportSectionBody
                v-else-if="ownerPart.purchased"
                key="owner-info"
                :data="ownerPart"
                :data-description="
                  ownerPart && ownerPart.data && ownerPart.data.description
                "
                label-tag="Extra context for the address"
                label-top="Owner"
                label-type="Type of the address owner"
                :owner-name="
                  ownerPart && ownerPart.data && ownerPart.data.owner
                "
                :tag-meta-data="
                  ownerPart && ownerPart.data && ownerPart.data.tags
                "
                :type-data="
                  ownerPart &&
                    ownerPart.data &&
                    ownerPart.data.type.name &&
                    ownerPart.data.type
                "
              />
            </transition-group>
          </template>
        </GlSection>

        <GlSection
          key="Risk score"
          class="mb-4"
          :green="showHoverOnSectionScore"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            Risk Score
          </template>

          <template #body>
            <transition-group name="fade-leave">
              <LockReportSection
                v-if="
                  !scorePart.purchased ||
                    startAddressCheckLoading ||
                    reportPartsLoading
                "
                key="RiskScoreLock"
                :current-hovered-part="currentHoveredPart"
                :loading-section="startAddressCheckLoading"
                :part-data="scorePart"
                score
                text="A number showing how risky the sources contributing to an address or transaction are."
                @button-click="part => $emit('click-unlock-now', part)"
                @update-hovered-part="
                  part => $emit('update-hovered-part', part)
                "
              />
              <div
                v-else-if="scorePart.purchased"
                key="RiskScore"
                class="flex justify-center"
              >
                <GlProgressBarChart
                  :calculation-loading="reportPartsLoading"
                  class="report-progress-bar-chart"
                  :has-funds-data="hasTxs"
                  :total-funds="
                    scorePart && scorePart.data && scorePart.data.totalScore
                  "
                />
              </div>
            </transition-group>
          </template>
        </GlSection>

        <GlSection
          key="Type Entity Full"
          class="mb-4"
          :green="showHoverOnSectionType"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            {{ typeEntityFullSectionText }}
          </template>

          <template #body>
            <LockReportSection
              v-if="
                !typesPart.purchased ||
                  startAddressCheckLoading ||
                  reportPartsLoading
              "
              :current-hovered-part="currentHoveredPart"
              :loading-section="startAddressCheckLoading"
              max-text-width="350px"
              :part-data="typesPart"
              text="Counterparties from which the address has received funds by type."
              type
              @button-click="part => $emit('click-unlock-now', part)"
              @update-hovered-part="part => $emit('update-hovered-part', part)"
            />

            <TypesEntityPart
              v-if="showTypesEntityPart"
              :active-part="activePart"
              :active-part-options="activePartOptions"
              :active-type-entity-item="activeTypeEntityItem"
              :address-part="addressPart"
              :entity-part="entityPart"
              :full-details-part="fullDetailsPart"
              :loading="reportPartsLoading"
              :types-part="typesPart"
              @change-active-part="e => (activePart = e)"
              @change-active-type-entity-item="e => (activeTypeEntityItem = e)"
            />
          </template>
        </GlSection>
        <GlSection
          v-if="!entityPart.purchased || startAddressCheckLoading"
          key="Entity"
          class="mb-4"
          :green="showHoverOnSectionEntity"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            Source of Funds by entities
          </template>

          <template #body>
            <LockReportSection
              :current-hovered-part="currentHoveredPart"
              entity
              :loading-section="startAddressCheckLoading"
              max-text-width="330px"
              :part-data="entityPart"
              text="A list of contributors with names of the identified entities."
              @button-click="part => $emit('click-unlock-now', part)"
              @update-hovered-part="part => $emit('update-hovered-part', part)"
            />
          </template>
        </GlSection>
        <GlSection
          key="Full"
          class="mb-4"
          :warning="showHoverOnSectionFull"
        >
          <template #title>
            Source of Funds Transactions
          </template>

          <template #body>
            <LockReportSection
              v-if="!fullDetailsPart.purchased || startAddressCheckLoading"
              :current-hovered-part="currentHoveredPart"
              full
              :loading-section="startAddressCheckLoading"
              max-text-width="600px"
              :part-data="fullDetailsPart"
              text="Information about the address owner, risk score, source of funds, and transactions in a single report."
              @button-click="part => $emit('click-unlock-now', part)"
              @update-hovered-part="part => $emit('update-hovered-part', part)"
            />
            <FullTxInfoSection
              v-else
              :active-part="activePart"
              :active-type-entity-item="activeTypeEntityItem"
              :address-part="addressPart"
              :full-details-part="fullDetailsPart"
            />
          </template>
        </GlSection>
      </transition-group>
    </div>
  </div>
</template>

<script>
// Components
import InfoBlock from '@/components/gl-info-block'
import GlSection from '@/components/gl-section'
import ReportSectionBody from '@/pages/report/components/section-components/ReportSectionBody'
import LockReportSection from '@/pages/report/components/section-components/LockReportSection'
import FullTxInfoSection from '@/pages/report/components/section-components/FullTxInfoSection'
import GlProgressBarChart from '@/components/charts/gl-progress-bar-chart'
import TypesEntityPart from '@/pages/report/components/table-components/TypesEntityPart'
import GlLoader from '@/components/gl-loader'
import RequestTokenReport from '@/pages/report/components/token-report/RequestTokenReport'

// Utils
import { formatDate } from '@/utils/format-date'
import { formatByPrice } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatAmount, txsAddPlus } from '@/utils/format-amount'
import { capitalizeFirstLetter } from '@/utils/text-formatter'
import { findColorByTypeScore, hex2rgba } from '@/utils/cytoskape-ui-rules'
import {
  formatFunds,
  ownerLabelFormatter,
  isOwnerExist,
  isClusterExist,
} from '@/utils/report-data-formatter'
// Vuex
import { mapState } from 'vuex'
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    InfoBlock,
    GlSection,
    ReportSectionBody,
    GlProgressBarChart,
    LockReportSection,
    TypesEntityPart,
    FullTxInfoSection,
    GlLoader,
    RequestTokenReport,
  },
  mixins: [deviceWidthMixin],
  props: {
    hasOnlyOneSource: {
      type: Boolean,
      default: false,
    },
    addressData: {
      type: Object,
      default: () => ({}),
    },
    allDataSource: {
      type: Array,
      default: () => [],
    },
    addressRiskySources: {
      type: Array,
      default: () => [],
    },
    addressKnownSources: {
      type: Array,
      default: () => [],
    },
    addressUnknownSources: {
      type: Array,
      default: () => [],
    },
    allDataSourceByOwner: {
      type: Array,
      default: () => [],
    },
    startAddressCheckLoading: {
      type: Boolean,
      default: false,
    },
    reportPartsLoading: {
      type: Boolean,
      default: false,
    },
    addressPart: {
      type: Object,
      default: () => ({}),
    },
    ownerPart: {
      type: Object,
      default: () => ({}),
    },
    scorePart: {
      type: Object,
      default: () => ({}),
    },
    typesPart: {
      type: Object,
      default: () => ({}),
    },
    entityPart: {
      type: Object,
      default: () => ({}),
    },
    fullDetailsPart: {
      type: Object,
      default: () => ({}),
    },
    partsData: {
      type: Object,
      default: () => ({}),
    },
    currentHoveredPart: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTypeEntityItem: {},
      activePart: { name: 'Risk Labels', value: 'type' },
      activePartOptions: [
        { name: 'Risk Labels', value: 'type' },
        { name: 'Owners', value: 'entity' },
      ],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinAmountInfo']),
    showTypesEntityPart() {
      return (
        (this.typesPart.purchased ||
          this.entityPart.purchased ||
          this.fullDetailsPart.purchased) &&
        !this.startAddressCheckLoading &&
        !this.reportPartsLoading
      )
    },
    hasTxs() {
      return Boolean(
        this.addressPart &&
          this.addressPart.data &&
          this.addressPart.data.numberOfTxs,
      )
    },
    typeEntityFullSectionText() {
      if (this.typesPart.purchased && this.entityPart.purchased) {
        return 'Risk Exposure'
      }
      return 'Risk Exposure by types'
    },
    showHoverOnSectionIncludes() {
      return this.currentHoveredPart.includes || []
    },
    showHoverOnSectionOwner() {
      return (
        this.showHoverOnSectionIncludes.includes('owner') ||
        this.currentHoveredPart.reportPartName === 'owner'
      )
    },
    showHoverOnSectionScore() {
      return (
        this.showHoverOnSectionIncludes.includes('score') ||
        this.currentHoveredPart.reportPartName === 'score'
      )
    },
    showHoverOnSectionType() {
      return (
        this.showHoverOnSectionIncludes.includes('type') ||
        this.currentHoveredPart.reportPartName === 'type'
      )
    },
    showHoverOnSectionEntity() {
      return (
        this.showHoverOnSectionIncludes.includes('entity') ||
        this.currentHoveredPart.reportPartName === 'entity'
      )
    },
    showHoverOnSectionFull() {
      return (
        this.currentHoveredPart &&
        this.currentHoveredPart.reportPartName === 'full'
      )
    },
  },
  methods: {
    formatDate,
    toComaSeparate,
    capitalizeFirstLetter,
    formatFunds,
    findColorByTypeScore,
    hex2rgba,
    formatAmount,
    ownerLabelFormatter,
    isOwnerExist,
    isClusterExist,
    formatByPrice,
    txsAddPlus,
    changeToken(token) {
      this.$emit('changeToken', token)
    }
  },
}
</script>

<style>
.report-lock-section-address-info {
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
  background-image: url('~@/assets/svg/background/report/lock-address-info-section.svg');
  height: 210px;
}
.report-progress-bar-chart {
  width: 50%;
}

@media (max-width: 1023px) {
  .report-lock-section-address-info {
    background-size: 100%;
    background-size: contain;
  }
  .report-progress-bar-chart {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .report-progress-bar-chart {
    width: 100%;
  }
  .report-lock-section-address-info {
    background-image: url('~@/assets/svg/background/report/lock-address-info-section-mobile.svg');
  }
}
</style>
